import { Component } from "@angular/core";
import pkgInfo from "./../../package.json";
import { DataHandlerService } from "./data-handler.service";

const GMKEY = "49a3e4db-f43c-4276-a052-975a0b82ba83";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent {
  version = pkgInfo.version;
  gameMasterKey = "gameMasterKey";
  isAdmin: boolean = false;

  constructor(private data: DataHandlerService) {
    if (localStorage.getItem(this.gameMasterKey) === GMKEY) {
      this.isAdmin = true;
    }
  }

  clearAllRooms() {
    this.data.clearAllRooms();
  }
}
