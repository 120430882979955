// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCWn4twmsxk2VGorJwnqOaxU7pIi6rJ_Kg",
    authDomain: "planning-poker-49524.firebaseapp.com",
    projectId: "planning-poker-49524",
    storageBucket: "planning-poker-49524.appspot.com",
    messagingSenderId: "1091049497015",
    appId: "1:1091049497015:web:8390d872320bb7f2934832",
    measurementId: "G-7Q4J0J144M",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
