import { Component, OnInit, Input } from '@angular/core';
import { Player } from 'src/app/data-handler.service';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.scss']
})
export class PlayerComponent implements OnInit {
  @Input() player: Player;
  @Input() showVotes?: boolean;

  constructor() { }

  ngOnInit(): void {
  }
}
