import { Component, OnDestroy, OnInit } from "@angular/core";
import { Card, DataHandlerService, ESTIMATION_TYPE, Player, Room } from "../data-handler.service";
import { Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";

const POSSIBLE_VALUES = [0.5, 1, 2, 3, 5, 8, 13, 20, 40, 100];
const POSSIBLE_SIGNS = {
  HEART: "heart",
  SPADE: "spade",
  DIAMOND: "diamond",
  CLUB: "club",
};
function getRandomSign() {
  const enumVals = Object.keys(POSSIBLE_SIGNS);
  return POSSIBLE_SIGNS[enumVals[Math.floor(Math.random() * enumVals.length)]];
}

@Component({
  selector: "app-deal-room",
  templateUrl: "./deal-room.component.html",
  styleUrls: ["./deal-room.component.scss"],
})
export class DealRoomComponent implements OnInit, OnDestroy {
  possibleValues = POSSIBLE_VALUES;
  estimationTypeEnum = ESTIMATION_TYPE;
  selectedCard: number = null;
  selectedHours: number = null;
  total = 0;

  room$: Observable<Room> = null;
  private _roomData: Room = null;
  player: Player;
  subscription: Subscription;

  constructor(private data: DataHandlerService, private router: Router) {
    if (!(this.data.currentPlayer && this.data.selectedRoom)) {
      this.router.navigate(["/"]);
    }
  }

  ngOnInit(): void {
    this.room$ = this.data.getActiveRoom();
    this.player = this.data.currentPlayer;

    this.subscription = this.room$.subscribe((room) => {
      this.total = this.#calculateTotal(room.activePlayers);
      this._roomData = room;
    });
  }

  ngOnDestroy(): void {
    this?.subscription?.unsubscribe();
  }

  async selectPoint(val) {
    this.selectedCard = val;
    await this.data.vote(new Card(val, getRandomSign()));
  }

  async changeEstimationType(estimationType: ESTIMATION_TYPE) {
    await this.data.changeEstimationType(estimationType);
  }

  async clearVote() {
    this.selectedCard = null;
    if (this._roomData.estimationType === ESTIMATION_TYPE.HOURS) {
      this.selectedHours = null;
    }
    await this.data.vote(null);
  }

  async clearAllVotes() {
    this.selectedCard = null;
    await this.data.clearAllVotes();
    this.hideVotes();
  }

  async showVotes() {
    await this.data.voteDisplay(true);
  }

  async hideVotes() {
    this.data.voteDisplay(false);
  }

  async kickAll() {
    await this.data.removeAllPlayersFromRoom();
    this.router.navigate(["/"]);
  }

  #calculateTotal(players: Player[]) {
    const currentPlayers = players.filter((p) => p?.selectedCard?.number);
    const sum = currentPlayers.map((p) => p?.selectedCard?.number).reduce((p, c) => p + c);
    return Math.round(sum / currentPlayers.length);
  }
}
