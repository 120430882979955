import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Observable } from "rxjs";
import { DataHandlerService, Player, Room } from "../data-handler.service";

const HATS_NR_TOTAL = 15;
const HATS_AVAILABLE = 5;

@Component({
  selector: "app-lobby",
  templateUrl: "./lobby.component.html",
  styleUrls: ["./lobby.component.scss"],
})
export class LobbyComponent implements OnInit {
  rooms$: Observable<any> = this.data.getRooms();
  hats: Set<number> = new Set();
  name: string = null;
  selectedHat: number = null;
  selectedRoom: string = null;

  constructor(private router: Router, private data: DataHandlerService) {
    while (this.hats.size < HATS_AVAILABLE) {
      this.hats.add(Math.floor(Math.random() * HATS_NR_TOTAL + 1));
    }
  }

  ngOnInit(): void {}

  selectHat(hatNumber: number) {
    this.selectedHat = hatNumber;
  }

  selectRoom(roomName: string) {
    this.selectedRoom = roomName;
  }

  async submit() {
    await this.data.registerPlayer(
      this.name,
      this.selectedHat,
      this.selectedRoom
    );
    this.router.navigate(["deal-room"]);
  }
}
