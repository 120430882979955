import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { take, map, filter } from "rxjs/operators";
import { firstValueFrom, Observable } from "rxjs";

export enum ESTIMATION_TYPE {
  STORY_POINTS = "story-points",
  HOURS = "hours",
}

export class Card {
  number: number;
  sign: string;

  constructor(number, sign) {
    this.number = number;
    this.sign = sign;
  }
}

export class Player {
  id?: string;
  name?: string;
  hat?: number;
  selectedCard?: Card;

  constructor(name, hat) {
    this.id = crypto.randomUUID();
    this.name = name;
    this.hat = hat;
  }
}

export class Room {
  id?: number;
  name: string;
  activePlayers: Player[] = [];
  showVotes: boolean = false;
  estimationType: ESTIMATION_TYPE;

  constructor(name, id?) {
    this.name = name;
    this.id = id;
    this.estimationType = ESTIMATION_TYPE.STORY_POINTS;
  }
}

@Injectable({
  providedIn: "root",
})
export class DataHandlerService {
  currentPlayer: Player = new Player(null, null);
  selectedRoom: string = null;

  constructor(private firestore: AngularFirestore) {}

  async #getDoc(docPath: string): Promise<any> {
    return await firstValueFrom(
      this.firestore
        .doc(docPath.toLowerCase())
        .snapshotChanges()
        .pipe(
          take(1),
          map((res) => res.payload.data())
        )
    );
  }

  async #updateDoc(docPath: string, payload) {
    return await this.firestore.doc(docPath.toLowerCase()).update(JSON.parse(JSON.stringify(payload)));
  }

  getRooms() {
    return this.firestore
      .collection("rooms")
      .valueChanges()
      .pipe(filter((e) => e !== null));
  }

  getActiveRoom(): Observable<Room | any> {
    return this.firestore.doc(`rooms/${this.selectedRoom.toLowerCase()}`).valueChanges();
  }

  async registerPlayer(name, hat, room) {
    const player = new Player(name, hat);
    this.currentPlayer = player;
    this.selectedRoom = room.toLowerCase();
    return await this.addPlayerToRoom(player, room);
  }

  async addPlayerToRoom(player: Player, room: string) {
    const docPath = `rooms/${room}`;
    const roomData: Room = await this.#getDoc(docPath);
    const checkPlayer = roomData.activePlayers.findIndex((p) => p.name === player.name);
    if (checkPlayer !== -1) {
      roomData.activePlayers[checkPlayer] = player;
    } else {
      roomData.activePlayers.push(player);
    }
    return await this.#updateDoc(docPath, roomData);
  }

  async removeAllPlayersFromRoom() {
    const docPath = `rooms/${this.selectedRoom}`;
    const roomData: Room = await this.#getDoc(docPath);
    roomData.activePlayers = [];
    roomData.showVotes = false;
    this.#updateDoc(docPath, roomData);
  }

  async vote(card: Card | null) {
    const docPath = `rooms/${this.selectedRoom}`;
    const roomData: Room = await this.#getDoc(docPath);
    const player: Player = roomData.activePlayers.find((p) => p.name === this.currentPlayer.name);
    player.selectedCard = card;
    this.#updateDoc(docPath, roomData);
  }

  async changeEstimationType(estimationType: ESTIMATION_TYPE) {
    const docPath = `rooms/${this.selectedRoom}`;
    const roomData: Room = await this.#getDoc(docPath);
    roomData.estimationType = estimationType;
    await this.#updateDoc(docPath, roomData);
  }

  async clearAllVotes() {
    const docPath = `rooms/${this.selectedRoom}`;
    const roomData: Room = await this.#getDoc(docPath);
    roomData.activePlayers.forEach((p) => (p.selectedCard = null));
    await this.#updateDoc(docPath, roomData);
  }

  async voteDisplay(display: boolean) {
    const docPath = `rooms/${this.selectedRoom}`;
    const roomData: Room = await this.#getDoc(docPath);
    roomData.showVotes = display;
    await this.#updateDoc(docPath, roomData);
  }

  /** testing purposes */
  async #resetAllRooms() {
    const rooms = ["werewolf", "nymeria", "akela", "okami", "amarok", "daos", "raksha", "greywolf", "design"];
    rooms.forEach((r, i) => this.#createRoom(r, i));
  }

  async #createRoom(name: string, id: number) {
    const room = JSON.parse(JSON.stringify(new Room(name, id)));
    return await this.firestore.collection("rooms").doc(name).set(room);
  }

  async clearAllRooms() {
    this.#resetAllRooms();
  }
}
