<mat-card class="lobby">
  <mat-card-title>Welcome!</mat-card-title>
  <mat-card-content>
    <mat-form-field class="name-input">
      <input matInput placeholder="Your name ..." [(ngModel)]="name" />
    </mat-form-field>
    <h3>Please select your hat</h3>
    <div class="hats">
      <div class="hat" *ngFor="let hat of hats" (click)="selectHat(hat)" [class.active]="selectedHat === hat">
        <img src="assets/images/hats/hat{{ hat }}.jpg" />
      </div>
    </div>
    <div class="rooms">
      <mat-label>Choose your room</mat-label>
      <mat-select [required]="true" [placeholder]="'Click here to choose a room'">
        <mat-option *ngFor="let room of rooms$ | async" [value]="room.id" (onSelectionChange)="selectRoom(room.name)">
          <span>{{ room.name | uppercase }} ({{ room.activePlayers.length }})</span>
        </mat-option>
      </mat-select>
    </div>
  </mat-card-content>
  <mat-card-actions>
    <button mat-raised-button color="primary" (click)="submit()" [disabled]="!(name && selectedHat && selectedRoom)">Submit</button>
  </mat-card-actions>
</mat-card>
