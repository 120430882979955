<div *ngIf="room$ | async">
  <h1 class="room-title">Room: {{ (room$ | async)?.name }}</h1>
  <div class="poker-table">
    <app-player
      *ngFor="let player of (room$ | async)?.activePlayers"
      [player]="player"
      [showVotes]="(room$ | async)?.showVotes"
      [class.current-player]="player.name === player.name"
    ></app-player>
  </div>

  <div class="console">
    <div class="point-selector" *ngIf="(room$ | async)?.estimationType === estimationTypeEnum.STORY_POINTS">
      <div
        class="point"
        *ngFor="let point of possibleValues"
        [innerHTML]="point"
        (click)="selectPoint(point)"
        [class.active]="selectedCard === point"
      ></div>
    </div>

    <div class="hour-estimation" *ngIf="(room$ | async)?.estimationType === estimationTypeEnum.HOURS">
      <mat-form-field class="hours-input">
        <input matInput placeholder="Hours estimation ..." type="number" [(ngModel)]="selectedHours" />
      </mat-form-field>
      <button mat-flat-button color="primary" (click)="selectPoint(selectedHours)">Estimate</button>
    </div>

    <div class="result">
      <h2 class="title">Result</h2>
      <h1 class="result-value" [innerHTML]="total" *ngIf="(room$ | async)?.showVotes"></h1>
      <h1 class="result-value" [innerHTML]="'...'" *ngIf="!(room$ | async)?.showVotes"></h1>
    </div>
  </div>

  <div class="estimation-actions">
    <button
      class="clear"
      mat-flat-button
      *ngIf="(room$ | async)?.estimationType === estimationTypeEnum.HOURS"
      (click)="changeEstimationType(estimationTypeEnum.STORY_POINTS)"
    >
      Change to Story Points
    </button>
    <button
      class="clear"
      mat-flat-button
      *ngIf="(room$ | async)?.estimationType === estimationTypeEnum.STORY_POINTS"
      (click)="changeEstimationType(estimationTypeEnum.HOURS)"
    >
      Change to Hours
    </button>
  </div>

  <div class="actions">
    <button class="clear" mat-flat-button (click)="clearVote()">Clear my vote</button>
    <button class="clear" mat-flat-button (click)="clearAllVotes()" color="accent">Clear ALL votes</button>
    <button class="show" mat-flat-button (click)="showVotes()" color="primary" *ngIf="!(room$ | async)?.showVotes">Show the votes</button>
    <button class="hide" mat-flat-button (click)="hideVotes()" *ngIf="(room$ | async)?.showVotes">Hide the votes</button>
    <button class="kick" mat-flat-button (click)="kickAll()" color="warn">Kick all players</button>
  </div>
</div>
