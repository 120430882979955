<div class="main">
  <!--  -->
  <mat-toolbar>
    <a href="/">
      <h1>Planning Poker</h1>
    </a>
    <span [innerHTML]="'&nbsp;v' + version"></span>
    <button
      class="game-master-clear"
      *ngIf="isAdmin"
      mat-raised-button
      color="primary"
      (click)="clearAllRooms()"
    >
      Clear all rooms
    </button>
  </mat-toolbar>
  <div class="container">
    <router-outlet></router-outlet>
  </div>
</div>
