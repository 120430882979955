import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { LobbyComponent } from "./lobby/lobby.component";
import { DealRoomComponent } from "./deal-room/deal-room.component";

const routes: Routes = [
  { path: "", redirectTo: "lobby", pathMatch: "full" },
  { path: "lobby", component: LobbyComponent },
  { path: "deal-room", component: DealRoomComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: "legacy" })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
