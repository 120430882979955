<div class="player">
  <div class="info">
    <div class="name" [innerHTML]="player.name"></div>
    <div class="hat">
      <img src="/assets/images/hats/hat{{ player.hat }}.jpg" alt="" />
    </div>
  </div>

  <section
    class="card"
    *ngIf="showVotes && player.selectedCard?.number"
    [attr.value]="showVotes && player.selectedCard?.number"
    [ngClass]="player.selectedCard.sign"
  >
    <div class="card__inner card__inner--centered">
      <div class="card__column">
        <div class="card__symbol"></div>
        <div class="card__symbol"></div>
      </div>
    </div>
  </section>

  <div class="card player-card-back" *ngIf="!showVotes || !player.selectedCard?.number" [class.has-card]="player.selectedCard?.number">
    <img src="/assets/images/card/bicycle-poker.jpg" alt="" />
  </div>
</div>
